const { create } = require('zustand');

const listOfGame = require('../../public/data/listofgame.json');
const listOfRecommendedGame = require('../../public/data/listOfRecommendedGame.json');
const listOfPopularGame = require('../../public/data/listOfPopularGame.json');
const listOfGameProvider = require('../../public/data/listOfGameProvider.json');
const listOfGameCategory = require('../../public/data/listOfGameCategory.json');

const useGameStore = create(() => {
	return {
		gameImageDomainBase: listOfGame.baseUrl,
		allGameData: { body: listOfGame.body, total: listOfGame.total } ?? [],
		recommendedGameData: listOfRecommendedGame,
		popularGameData: listOfPopularGame,
		gameProviderData: listOfGameProvider,
		gameCategoryData: listOfGameCategory
		// setAllGameData: () => ({})
	};
});

export default useGameStore;
